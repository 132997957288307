import { ReactComponent as HomeIcon } from "./assets/icons/home.svg";
import { ReactComponent as DiscountIcon } from "./assets/icons/discount.svg";
import { ReactComponent as ActivityIcon } from "./assets/icons/activity.svg";
// import { ReactComponent as CampaignIcon } from "./assets/icons/campaign.svg";
import { ReactComponent as LogoutIcon } from "./assets/icons/logout.svg";

// ADMIN ICONS
import { ReactComponent as Dashboard } from "./assets/icons/Dashboard.svg";
import { ReactComponent as CreateCampaignIcon } from "./assets/icons/Create_Campaign.svg";
import { ReactComponent as CampaignReportIcon } from "./assets/icons/Campaign_Report.svg";
import { ReactComponent as AgentReportIcon } from "./assets/icons/Agent_Report.svg";
import { ReactComponent as CreateAgentIcon } from "./assets/icons/Agent.svg";
import { ReactComponent as CreateUser } from "./assets/icons/add_user.svg";
import { ReactComponent as CampaignIcon } from "./assets/icons/Campaign.svg";

import {
  //AGENT COMPONENT
  AgentDashbaord,
  AgentCampaign,
  AgentCustomerList,
  AgentCampReport,
  ManuvalListCamp,
  //ADMIN COMPONENTS
  AdminCampaignList,
  AdminDashbaord,
  AdminAgents,
  CreateAgent,
  CreateCampaign,
  CampaignReport,
  AgentReport,
  OutgoingCallReport,
  //COMMONURLS
  Notfound,
  Login,
  Unauthorized,
  Activity,
  NewCampaign,
  CampaignCustomers
} from "./pages/index";
import { menu } from "@material-tailwind/react";
import AddUser from "./pages/Admin/AddUser";

export const Icon = (props) => {
  return (
    <>
      <svg className="w-[35px] h-[35px]" viewBox="0 0 30 30">
        <defs>
          <linearGradient
            x1="30%"
            y1="92.034%"
            x2="50%"
            y2="7.2%"
            x3="45%"
            y3="50%"
            id="a"
          >
            <stop offset="40%" stopColor="#AFD462" />
            <stop offset="60%" stopColor="#6BC698" />
            <stop offset="80%" stopColor="#0FB1E2" />
          </linearGradient>
        </defs>
        <props.icon
          className={` group-hover:fill-[url(#a)] ${
            props.active ? "fill-[url(#a)]" : "fill-[#A2A4A6]"
          } `}
        ></props.icon>
      </svg>
    </>
  );
};

const Adminroutes = [

  {
    type: "collapse",
    name: "Dashboard",
    key: "admin_dashboard",
    icon: Dashboard,
    path: "dashboard",
    component: <AdminDashbaord />,
  },

  {
    type: "collapse",
    name: "Campaigns",
    key: "campaign_list",
    icon: CampaignIcon,
    path: "campaign/list",
    component: <AdminCampaignList />,
  },

  // {
  //   type: "collapse",
  //   name: "Create Campaigns",
  //   key: "create_campaign",
  //   icon:CreateCampaignIcon,
  //   path: "campaign/create",
  //   component: <NewCampaign />,
  // },

  {
    type: "collapse",
    name: "Agents",
    key: "agent_list",
    icon: CreateAgentIcon,
    path: "list/agent",
    component: <AdminAgents />,
  },

  {
    type: "collapse",
    name: "Campaign Report",
    key: "campgin_report",
    icon: CampaignReportIcon,
    path: "report/campaign",
    component: <CampaignReport />,
  },

  {
    type: "collapse",
    name: "Agent Report",
    key: "agent_report",
    icon: CampaignReportIcon,
    path: "report/agent",
    component: <AgentReport />,
  },
  
  {
    type: "collapse",
    name: "Campaign Customers",
    key: "campaign_customers",
    icon: CampaignReportIcon,
    path: "campaign/customers",
    component: <CampaignCustomers />,
  },
];


export default Adminroutes;

export const AgentRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "agent_dashboard",
    icon: Dashboard,
    path: "dashboard",
    component: <AgentDashbaord />,
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaign_report",
    icon: CampaignIcon,
    path: "campaigns/:type/",
    component: <AgentCampReport />,
  },

  // {
  //   type: "collapse",
  //   name: "Outgoing report",
  //   key: "outgoing-call-report",
  //   icon: CampaignReportIcon,
  //   path: "agent/outgoing-call-report",
  //   component: <OutgoingCallReport />,
  // },
];


export const CommonRoutes = [
  { name: "Login", icon: "StoreIcon", path: "login", component: <Login /> },
  {
    name: "Unathorized",
    icon: "StoreIcon",
    path: "unauthorized",
    component: <Unauthorized />,
  },
  { name: "404", icon: "StoreIcon", path: "*", component: <Notfound /> },
];
