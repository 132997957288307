import React, { useState, useEffect } from "react";
import Feedback01 from "./Feedback01";
import Feedback02 from "./Feedback02";
import Feedback03 from "./Feedback03";
import useFetch from "../../hooks/useFetch";
import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { FEEDBACK } from "../../utils/ApiUrls";
import { TopDisappearingMessage } from "../../utils/Common";
import useAuth from "../../hooks/useAuth";
import useSocket from "../../hooks/useWebsocket";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";

const CallFeedback = (props) => {
  const [curfback, setcurrfback] = useState("Feedback01");
  const FBdata = props.FBdata;
  const setFBdata = props.setFBdata;
  const fetchData = useFetch();
  const { auth } = useAuth();
  const [isReady, val, send] = useSocket();
  const viewfbvalinitial = props?.viewfb || false;
  const [viewfb, setviewfb] = useState(false);
  const [editfbpermission, seteditfbpermission] = useState(false);
  console.log("Feedback js   customer data    :    ", props.customer);

  useEffect(() => {
    if (auth?.user_details?.type === "agent") {
      seteditfbpermission(true);
    } else {
      seteditfbpermission(true);
    }
  }, []);

  useEffect(() => {
    const data = val?.data || { callstatus: "" };
    const action = val?.action;
    console.log("campaign mode ------>  :   ", props.camp_data.campaign_mode);
    // if (props.camp_data.campaign_mode == "manual") {
    //   if (feedbacksubmitted) {
    //     console.log("This is manual call ...  Feedback Submitted");
    //   }
    // }
    console.log(
      typeof data.closeInSeconds,
      "Feedback Hide Tiem --------------------------------->   : ",
      data.closeInSeconds
    );
    if (props.camp_data.campaign_mode == "auto") {
      if (data.callstatus == "end") {
        TopDisappearingMessage(
          "top-start",
          data.closeInSeconds,
          "warning",
          "Next customer in " + data.closeInSeconds + " seconds"
        );

        setTimeout(function () {
          submitData();
          props.setfback(false);
        }, parseInt(data.closeInSeconds) * 1000);
      }
    }
  }, [val]);

  const CloseFB = () => {
    props.setfback(false);
    props.close(false);
  };

  const submitData = async () => {
    console.log(props.customer);
    let user_id;

    props.setfback(false);
    props.close(false);
    if (props.setFeedbackSubmitted != undefined) {
      props.setFeedbackSubmitted(true);
    }

    if (auth?.user_details?.type == "agent") {
      user_id = auth?.user_details?.id;
    } else {
      user_id = props.FBdata.agent_id || 0;
    }
    let url = FEEDBACK + user_id + "/" + props.customer.id + "/";
    const res_data = await fetchData("post", FBdata, url);
    if (res_data?.data == "success") {
      console.log(props.campaignRepotData, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      if (props.campaignRepotData != undefined) {
        const updatedData = props.campaignRepotData.map((item) => {
          if (item && props.FBdata && item.call_uid === props.FBdata.uid) {
            return {
              ...item,
              call_feedback:
                item.call_feedback === null ? res_data?.id : item.call_feedback,
            };
          }
          return item;
        });
        props.setCampaignReportData(updatedData);
      }

      setFBdata((prevState) => {
        return { ...prevState, id: res_data?.id };
      });
      TopDisappearingMessage(
        "top-start",
        2000,
        "success",
        "Successfully submited the feedback",
        true
      );
    } else {
      alert("Error in Updating the agent status");
    }
  };

  return (
    <div
      id="defaultModal"
      className="fixed  p-4  overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div className="max-w-2xl md:h-auto">
        <div
          style={{ left: "15rem" }}
          className="relative lg:bottom-[5rem] lg:top-[5rem]  right-0 bg-white rounded-lg shadow dark:bg-gray-700"
        >
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-green-400 h-2.5 rounded-full"
              style={
                curfback == "Feedback01"
                  ? { width: "33%" }
                  : curfback == "Feedback02"
                  ? { width: "67%" }
                  : curfback == "Feedback03"
                  ? { width: "100%" }
                  : { width: "100%" }
              }
            ></div>
          </div>

          <div className="p-6 rounded-md space-y-8">
            <div className="flex justify-between">
              <h1 className="font-bold text-lg">Feedback</h1>
              <div className="flex justify-end gap-4">
                <button className="ml-auto" onClick={CloseFB}>
                  <Cross />
                </button>

                {editfbpermission && (
                  <button
                    className="w-[20px]"
                    onClick={() => {
                      setviewfb(false);
                    }}
                  >
                    <Edit
                      className={`width="26" height="26" fill="blue"`}
                      viewBox="0 0 26 26"
                      fill="green"
                      cx="8.5"
                      cy="8.5"
                      r="6"
                      fillRule="evenodd"
                      fillOpacity=".8"
                    />
                  </button>
                )}
              </div>
            </div>

            {curfback == "Feedback01" ? (
              <Feedback01
                customer={props.customer}
                setcurrfback={setcurrfback}
                next="Feedback02"
                showCustomerNo={props.showCustomerNo}
              />
            ) : curfback == "Feedback02" ? (
              <Feedback02
                answers={FBdata.answers}
                setcurrfback={setcurrfback}
                setFBdata={setFBdata}
                questions={props?.camp_data?.questionArray || []}
                next="Feedback03"
                back="Feedback01"
                // viewfb={viewfb}
              />
            ) : curfback == "Feedback03" ? (
              <Feedback03
                fbanswer={FBdata.fbanswer}
                submitData={submitData}
                FBdata={FBdata}
                setcurrfback={setcurrfback}
                setFBdata={setFBdata}
                feedback={props?.camp_data?.feedBackLabels || []}
                back="Feedback02"
                // viewfb={viewfb}
                editfbpermission={editfbpermission}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CallFeedback);
